import React from "react"
import SEO from "../../components/seo/seo"
import AnimateTexts from "../../components/animations/animate-texts"
import AnimateFade from "../../components/animations/animate-fade"
import CustomContainer from "../../components/general/contianer"
import InlineLink from "../../components/general/inline-link"
import Footer from "../../components/footer/footer"

const TermsAndConditions = ({transitionStatus}) => {

    return (
        <>
            <SEO description={""} title={"Privacy Policy"} pathname={"/privacy"}/>
            <section className={"privacy_section bg-background-dark py-12 sm:py-32"} style={{ marginTop: "112px" }}>
                <div className={"flex justify-center"}>
                    <AnimateTexts className={`privacy_header`} transitionStatus={transitionStatus} delay={400}>
                        <h1 className={"font-bold font-sans text-3xl md:text-4xl lg:text-5xl 2xl:text-6xl text-center px-4 max-w-2xl"} data-text-animate={true}>
                            Privacy Policy
                        </h1>
                        <p className={"font-medium font-sans text-s, opacity-60 text-center px-4 max-w-2xl mt-2"} data-text-animate={true}>
                            Effective 12 Nov. 2021
                        </p>
                    </AnimateTexts>
                </div>
            </section>
            <section className={"privacy_section_data py-12"}>
                <CustomContainer className={"flex justify-center"}>
                    <div className={"max-w-2xl font-sans text-sm"}>
                        This Privacy Policy describes how GROOMD and its subsidiaries and affiliates (“Groomd,” “we,” “us,” and/or “our”) handle personal data we collect online (through our websites) and offline (through customer support channels and in-person promotional activities). We call all of these the “Services”. This Privacy Policy explains the types of personal data we collect and process, how we may use and share the data, and the choices that are available to you with respect to our handling of your personal data.
                        <br/>
                        <br/>
                        <br/>

                        <h2 className={"font-bold text-lg capitalize"}>
                            1. INFORMATION WE COLLECT
                        </h2>
                        <br/>

                        <h3 className={"font-medium text-base"}>
                            Information we collect directly from you
                        </h3>
                        <br/>
                        We collect the information you provide directly to us, such as when you open an account, place an order, ask to receive emails, contact customer service, or interact with us on social media. The types of personal data we may collect directly from you include:
                        <br/>
                        <br/>
                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                            <li>Contact information, such as your name, email address, mailing address, and phone number;</li>
                            <li>Account information, such as your username and password;</li>
                            <li>Billing information, such as credit card details and billing address;</li>
                            <li>Optional information you may choose to provide, such as your age, gender; and</li>
                            <li>Any other information you choose to provide, such as product reviews, responses to surveys or quizzes or to receive customer support.</li>
                            <li>Information about your use of our services</li>
                            <li>Information about your use of the Services, such as the products you buy or express interest in.</li>
                        </ul>
                        <br/>
                        <br/>
                        <h3 className={"font-medium text-base"}>
                            Information we collect from other sources
                        </h3>
                        <br/>
                        We may collect information about you from other sources, including:
                        <br/>
                        <br/>
                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                            <li>Other users, such as through our refer-a-friend program or e-gift card offerings. If you choose to participate in our refer-a-friend program or purchase an e-gift card for someone else, we will collect information about your friend (such as a name and email address) in order to invite your friend to shop with us or send them their e-gift card.</li>
                            <li>Third-party social media services. When you access the Services through a social network, we collect information about you from the social network in accordance with your settings on the social network. If you interact with us on social media, we will collect information about those interactions. The information we may collect includes your name and email address.</li>
                            <li>Other unaffiliated third parties, such as advertising networks, media monitoring companies, and publicly available sources.</li>
                        </ul>
                        <br/>
                        <br/>
                        <h3 className={"font-medium text-base"}>
                            Information we derive
                        </h3>
                        <br/>
                        We may derive information or draw inferences about you based on the information we collect. For example, we may make inferences about your location based on your IP address or infer that you are looking to purchase certain products based on your browsing behavior and past purchases.
                        <br/>
                        <br/>

                        <h3 className={"font-medium text-base"}>
                            Information we collect by automated means
                        </h3>
                        <br/>
                        When you visit our sites, interact with our communications, or visit our stores, we collect certain information automatically. To collect this information, we may use cookies, web beacons, and similar technologies. A “cookie” is a text file that websites send to a visitor‘s computer or other internet-connected device to uniquely identify the visitor’s browser or to store information or settings in the browser. A “web beacon,” also known as a pixel tag or clear GIF, is used to transmit information back to a web server. We may also collect information about your online activities over time and across third-party websites. The information we collect automatically may include:
                        <br/>
                        <br/>
                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                            <li>URLs that refer visitors to our websites;</li>
                            <li>Search terms used to reach our websites;</li>
                            <li>Details about the emails we send, such as opens, clicks, and unsubscribes;</li>
                            <li>Details about the devices that are used to access our websites (such as IP address, browser information, device information, and operating system information);</li>
                            <li>Details about your interaction with our websites (such as the date, time, length of stay, and specific pages accessed during your visits to our websites, referral activity, and which emails you may have opened);</li>
                            <li>Usage information (such as the number and frequency of visitors to our websites).</li>
                            <li>We may associate this information with your Groomd account if you have one, the device you use to connect to our Services, or email or social media accounts that you use to engage with Groomd.</li>
                        </ul>
                        <br/>
                        <br/>
                        <br/>
                        <h2 className={"font-bold text-lg capitalize"}>
                            2. ADVERTISING AND ANALYTICS SERVICES PROVIDED BY OTHERS
                        </h2>
                        <br/>
                        We may allow others to provide analytics services and serve advertisements on our behalf across the internet and in mobile applications. They may use cookies, web beacons, and other technologies to collect information about your use of the Services and other websites and applications, including your IP address, device ID, web browser, mobile network information, pages viewed, time spent on pages or in apps, links clicked, and conversion information. This information may be used by GROOMD and others to, among other things, analyze and track data, determine the popularity of content, deliver advertising and content targeted to your interests on our Services and other websites, and better understand your online activity.
                        <br/>
                        <br/>
                        We may also work with third parties to serve ads to you as part of a customized campaign on third-party platforms (such as Facebook or Google). As part of these ad campaigns, we or third-party platforms may convert information about you, such as your email address, into a unique value that can be matched with a user account on these platforms to allow us to learn about your interests and to serve you advertising that is customized to your interests. Note that the third-party platforms may offer you choices about whether you see these types of customized ads.
                        <br/>
                        <br/>
                        <br/>

                        <h2 className={"font-bold text-lg capitalize"}>
                            3. HOW WE USE YOUR INFORMATION
                        </h2>
                        <br/>
                        We may use the information we collect to deliver the products and Services you request, to maintain and customize your account and our interactions with you (such as on our digital properties), and to provide, maintain, and improve our Services. We also use the information we collect to:
                        <br/>
                        <br/>
                        <ul style={{ listStyleType: "circle", marginLeft: "20px" }}>
                            <li>Create and manage your online accounts and profiles;</li>
                            <li>Communicate with you about our Services, including to tell you about products and services that may be of interest to you;</li>
                            <li>Complete the transactions you request, perform our contractual obligations, and use as otherwise anticipated within the context of our ongoing business relationship;</li>
                            <li>Respond to your requests, inquiries, comments, and suggestions;</li>
                            <li>Facilitate your engagement with the Services, including to enable you to post comments and reviews, to engage with other customers, and to post on social media;</li>
                            <li>Offer contests, sweepstakes, loyalty programs or other promotions;</li>
                            <li>Personalize your online experience and the advertisements you see when you use the Services or third-party platforms based on your preferences, interests, purchasing history and browsing behavior;</li>
                            <li>Monitor, audit and analyze trends, usage, and activities in connection with our Services;</li>
                            <li>Carry out short-term activities and other internal uses related to the products or services you purchase from us or your ongoing relationship with us;</li>
                            <li>Conduct internal research and development;</li>
                            <li>Detect, investigate, and respond to security incidents and protect against illegal or objectionable activities, including the unauthorized use of the Services, and protect the rights and property of Groomd and others;</li>
                            <li>Debug, identify and repair errors that impair existing intended functionality of our Services;</li>
                            <li>Comply with our legal obligations, including those required for you to benefit from rights recognized by law, or any regulatory requirements or provisions; and</li>
                            <li>Conduct or administer surveys and other market research.</li>
                        </ul>
                        <br/>
                        <br/>
                        <br/>

                        <h2 className={"font-bold text-lg capitalize"}>
                            4. WHO MAY HAVE ACCESS TO YOUR INFORMATION
                        </h2>
                        <br/>
                        <span className={"font-medium underline"}>Within Groomd:</span> We may disclose certain of your personal data to Groomd affiliates and personnel who need to know the information for the purposes described above, including personnel in the customer service and information technology departments.
                        <br/>
                        <br/>
                        <span className={"font-medium underline"}>Vendors, Consultants Service Providers:</span> We may use vendors, consultants, and service providers acting on Groomd’s behalf to perform some of the services described above. For example, we share certain information with service providers who assist with the processing of credit cards and payments, hosting, managing and servicing our data, distributing emails, conducting research and analysis, advertising, analytics, or administering certain services and features. We also may share information about you with our professional advisors, including accountants, auditors, lawyers, insurers and bankers, if needed. These vendors, consultants and service providers may change over time, but we will always use trusted service providers who we require to take appropriate security measures to protect your personal data in line with our policies. We only permit them to process your personal data for specified purposes and, as appropriate, in accordance with our instructions and the provisions of this Policy and applicable law.
                        <br/>
                        <br/>
                        <span className={"font-medium underline"}>Other Third Parties:</span> In certain limited circumstances, we share and/or are obligated to share your personal data with other third parties, including (a) to comply with our obligations, to protect the rights and property of Groomd, our customers and the public, to cooperate with law enforcement investigations, and to detect and respond to suspected illegal activity and threats to the health or safety or any person or of our systems or services; (b) in connection with, or during negotiations of, any merger, joint venture, sale of company assets, financing, or acquisition of all or a portion of our business, assets or stock by another company (including in connection with any bankruptcy or similar proceedings); and/or (c) with your consent and at your direction.
                        <br/>
                        <br/>
                        <span className={"font-medium underline"}>Advertising Companies:</span> We work with third party advertising companies (such as advertising networks) to serve advertisements on our behalf. For additional information, see the "Advertising and Analytics Services Provided by Others" section.
                        <br/>
                        <br/>
                        When you provide a product review or post other user content, that content may be publicly posted. Other users may be able to see your name or other information about you that you post. In certain instances, we may also share aggregated or de-identified information that cannot reasonably be used by those third parties to identify you.
                        <br/>
                        <br/>
                        <br/>

                        <h2 className={"font-bold text-lg capitalize"}>
                            4. OPTING OUT OF EMAIL MARKETING
                        </h2>
                        <br/>
                        You may unsubscribe from our promotional emails at any time by following the instructions included in those emails. If you opt out of receiving such communications, note that we may continue to send you non-promotional emails (such as order confirmation emails or emails about changes to our legal terms).
                        <br/>
                        <br/>
                        <br/>

                        <h2 className={"font-bold text-lg uppercase"}>
                            4. Links to Other Websites and Third-Party Content
                        </h2>
                        <br/>
                        We may provide links to third-party websites, services, plug-ins and applications, such as Facebook and Google, that are not operated or controlled by Groomd. This Privacy Policy does not apply to such third-party services, and we cannot take responsibility for the content, privacy policies, or practices of third-party services. We encourage you to review the privacy policies of any third-party services before providing any information to or through them.
                        <br/>
                        <br/>
                        The Services may offer social sharing features and other integrated tools (such as the Facebook "Like" or "Share" button or the Twitter “Tweet” button) which let you share actions you take on our Services with other media. Your use of such features enables the sharing of information with your friends or the public, depending on the settings you establish with the entity that provides the social sharing feature. For more information about the purpose and scope of data collection and processing in connection with social sharing features, please visit the privacy policies of the entities that provide these features.
                        <br/>
                        <br/>
                        <br/>

                        <h2 className={"font-bold text-lg uppercase"}>
                            5. DATA RETENTION
                        </h2>
                        <br/>
                        Our retention periods for personal data are based on business needs and legal requirements. We retain personal data for as long as is necessary for the processing purpose(s) for which the data was collected, and any other permissible, related purpose. For example, we may retain certain transaction details and correspondence until the time limit for claims arising from the transaction has expired. When we no longer need to use your personal data, it is removed from our systems and records or anonymized so that you can no longer be identified from it.<br/>
                        <br/>
                        <br/>
                        <br/>

                        <h2 className={"font-bold text-lg uppercase"}>
                            6. Changes to Our Privacy Policy
                        </h2>
                        <br/>
                        We may change this Privacy Policy from time to time. If we do so, we will post the updated policy on our sites and will indicate when the Privacy Policy was last revised. If we make any material changes, we will provide you with additional notice. You should periodically review our current Privacy Policy to stay informed of our personal data practices.
                        <br/>
                        <br/>
                        <br/>
                    </div>
                </CustomContainer>
            </section>
            <Footer transitionStatus={transitionStatus}/>

        </>
    )

}

export default TermsAndConditions